html, body, #__next {
  height: 100%;
}

h1 {
  font-size: 2em;
  font-weight: 700;
}

.card {
  box-shadow: 0px -1px 44px -22px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px -1px 44px -22px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px -1px 44px -22px rgba(0,0,0,0.75);
  border-radius: 12px;
}

.description-label {
  color: red
}