/* ---------------------------------- FORMS --------------------------------- */

.ant-input,
.ant-input-number,
.ant-input-password,
.ant-picker {
  border-radius: 4px;
  width: 100%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
  width: 100% ;
}

/* --------------------------------- BUTTONS -------------------------------- */

.ant-btn-primary {
  color: #ffffff;
  background-color: #0d43a5;
  padding: 4px 16px;
  height: 32px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  color: #fff;
  border-color: #7482dd;
  background: #7482dd;
}